import React from "react";
import { Link } from "gatsby";
import { CommunityMapToggleElement } from "./elements.js";

const CommunityMapToggle = ({ isMap }) => {
  return (
    <CommunityMapToggleElement>
      <li className={isMap ? "" : "selected"}>
        <Link to={`/community/`}>List</Link>
      </li>
      <li className={isMap ? "selected" : ""}>
        <Link to={`/explore-our-district/`}>Map</Link>
      </li>
    </CommunityMapToggleElement>
  );
};

export default CommunityMapToggle;
