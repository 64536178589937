import React from "react";
import Layout from "./../../components/Layout";
import CommunityHero from "./../../components/CommunityHero";
import CommunityFilter from "./../../components/CommunityFilter";
import CommunityCardHolder from "./../../components/CommunityCardHolder";
import CommunityMapToggle from "./../../components/CommunityMapToggle";
import { useStaticQuery, graphql } from "gatsby";

// TODO: weird interaction between

const CommunityFrontPage = () => {
  const [currentTypes, setCurrentTypes] = React.useState([]);
  const [changedTypes, setChangedTypes] = React.useState(false);
  const communityQuery = useStaticQuery(graphql`
    query {
      craftDiscoverTheAvenueDiscoverTheAvenueEntry {
        title
        discoverCallout
      }
      allCraftCommunityCommunityMemberEntry(
        filter: {
          isDraft: { eq: false }
          communityIsThisAnAlserkalProperty: { eq: false }
          communityMemberHideInListView: { ne: true }
        }
      ) {
        nodes {
          id
          communityIsThisAnAlserkalProperty
          communityMemberSlug
          communityMemberType
          communityMemberWebsite
          communityMemberEmailAddress
          subtitle
          communityMemberOpeningHours
          communityMemberLocation
          communityMemberDescription
          communityMemberContactDetails {
            method
            value
          }
          slug
          title
          headerImage {
            ... on Craft_editorialAssets_Asset {
              wideImage: localFile {
                publicURL
                childImageSharp {
                  fixed(width: 295) {
                    src
                    ...GatsbyImageSharpFixed
                  }
                }
              }
            }
          }
        }
      }
    }
  `);
  const communityMembers =
    communityQuery.allCraftCommunityCommunityMemberEntry.nodes;
  // console.log(communityMembers.map((x) => x.headerImage[0]));
  const {
    title,
    discoverCallout,
  } = communityQuery.craftDiscoverTheAvenueDiscoverTheAvenueEntry;

  const checkTypes = (typesArray) => {
    for (let i = 0; i < typesArray.length; i++) {
      if (currentTypes.indexOf(typesArray[i]) > -1) {
        return true;
      }
    }
    // console.log(typesArray);
    return false;
  };

  // console.log(currentTypes);
  // console.log(communityMembers);
  return (
    <Layout backgroundColor={"var(--white)"} footerColor={"var(--red)"}
    title="Alserkal Avenue | A Community for
    Contemporary Art & Homegrown Concepts"
    SEODescription="If you want dynamic cultural experiences, from
    contemporary art and photography to spinning and yoga
    classes, discover Alserkal Avenue today."
    >
      <div>
        <CommunityHero topLine={title} text={discoverCallout} />
        <CommunityMapToggle />
        <CommunityFilter
          selected={(e) => {
            // console.log("selected came back");
            setCurrentTypes(e);
            setChangedTypes(() => !changedTypes);
          }}
        />
        <CommunityCardHolder
          cards={communityMembers.filter((x) =>
            currentTypes.length === 0 ? true : checkTypes(x.communityMemberType)
          )}
          closeOverlay={changedTypes}
        />
      </div>
    </Layout>
  );
};

export default CommunityFrontPage;
