import React from "react";
import PropTypes from "prop-types";
import Img from "gatsby-image";
import { Link, navigate } from "gatsby";
import Masonry from "react-masonry-css";
import { CommunityCardHolderDiv, CommunityCard, OverlayDiv } from "./elements";
import CloseButton from "./../icons/CloseButton";
import { H5Medium, H4Strong } from "./../Typography";
import { SmallButton } from "./../Buttons";

const Overlay = ({
  communityMember,
  photoCoordinates,
  holderCoordinates,
  closeThis,
}) => {
  // console.log(communityMember);
  // console.log(holderCoordinates);
  // console.log(photoCoordinates);
  // console.log(communityMember);
  const myLeft = photoCoordinates.x - holderCoordinates.x - 50;
  const myTop = photoCoordinates.top - holderCoordinates.top - 88;
  const myColumn = Math.floor(myLeft / 315); // 0, 1, 2, or 3
  return (
    <OverlayDiv left={myLeft} top={myTop} myColumn={myColumn}>
      <div>
        <div id={"image-contacts"}>
          <div id={"image"}>
            {communityMember.headerImage &&
            communityMember.headerImage.length &&
            communityMember.headerImage[0].wideImage &&
            communityMember.headerImage[0].wideImage.childImageSharp ? (
              <Img
                fixed={
                  communityMember.headerImage[0].wideImage.childImageSharp.fixed
                }
              />
            ) : communityMember.headerImage.length &&
              communityMember.headerImage[0].wideImage ? (
              <img src={communityMember.headerImage[0].wideImage.publicURL} />
            ) : (
              <div className="placeholder" />
            )}
            <Link to={`/community/${communityMember.communityMemberSlug}/`}>
              <SmallButton color={"var(--red)"} backgroundColor={"transparent"}>
                Find out more
              </SmallButton>
            </Link>
          </div>
          <div id={"contacts"} className="contactdetails">
            <div>
              <H4Strong>Contact Details</H4Strong>
              <div>
                {communityMember.communityMemberContactDetails &&
                communityMember.communityMemberContactDetails.length
                  ? communityMember.communityMemberContactDetails.map(
                      (method, index) => (
                        <p
                          key={index}
                          className={
                            method.value.indexOf("+971") > -1 ||
                            method.value.indexOf("http")
                              ? ""
                              : "canwrap"
                          }
                        >
                          {method.value.indexOf("http") > -1 ? (
                            <a
                              href={method.value}
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              {method.method}
                            </a>
                          ) : method.value.indexOf("+971") > -1 ? (
                            <a
                              href={`tel:${method.value}`}
                              target="_blank"
                              rel="noopener noreferrer"
                              className="nounderline"
                            >
                              {method.method} {method.value}
                            </a>
                          ) : (
                            `${method.method} ${method.value}`
                          )}
                        </p>
                      )
                    )
                  : null}
                <p>
                  <a
                    href={`mailto:${communityMember.communityMemberEmailAddress}`}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="nounderline"
                  >
                    {communityMember.communityMemberEmailAddress}
                  </a>
                </p>
                <p>
                  <a
                    href={communityMember.communityMemberWebsite}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Website
                  </a>
                </p>
              </div>
            </div>
            <div>
              <H4Strong>Opening Hours</H4Strong>
              <p className="canwrap">
                {communityMember.communityMemberOpeningHours}
              </p>
            </div>
            <div>
              <H4Strong>Location</H4Strong>
              <p className="canwrap">
                {communityMember.communityMemberLocation}
              </p>
              <SmallButton
                onClick={() => {
                  navigate(
                    `/explore-our-district?location=${encodeURI(
                      communityMember.communityMemberLocation
                    )}`
                  );
                  // goToMap(communityMemberLocation);
                }}
                color={"var(--red)"}
                backgroundColor={"transparent"}
              >
                View on map
              </SmallButton>
            </div>
          </div>
        </div>
        <div
          id={"details"}
          dangerouslySetInnerHTML={{
            __html: communityMember.communityMemberDescription,
          }}
        />
      </div>
      <CloseButton
        color={"var(--red)"}
        hoverColor={"var(--white)"}
        onClick={closeThis}
      />
    </OverlayDiv>
  );
};

const CommunityCardHolder = ({ cards, closeOverlay }) => {
  const [currentlySelected, setCurrentlySelected] = React.useState(null);
  const [overlayOn, setOverlayOn] = React.useState(false);
  const [oldCloseOverlay, setOldCloseOverlay] = React.useState(false);
  const holderRef = React.useRef(null);
  React.useEffect(() => {
    if (closeOverlay !== oldCloseOverlay) {
      // console.log("Closing overlay!");
      setOverlayOn(false);
      setOldCloseOverlay(closeOverlay);
    }
    // console.log(closeOverlay, oldCloseOverlay);
  }, [closeOverlay]);
  return (
    <CommunityCardHolderDiv ref={holderRef}>
      {cards.length ? (
        <Masonry
          breakpointCols={{ default: 4, 1270: 3, 950: 2, 635: 1 }}
          className="my-masonry-grid"
          columnClassName="my-masonry-grid_column"
        >
          {cards.map((card, index) => {
            return (
              <CommunityCard
                key={index}
                onClick={(e) => {
                  e.preventDefault();
                  setCurrentlySelected(card);
                  setOverlayOn(true);
                }}
              >
                <a>
                  {card.headerImage.length &&
                  card.headerImage[0] &&
                  card.headerImage[0].wideImage &&
                  card.headerImage[0].wideImage.childImageSharp ? (
                    <div id={card.slug.replace(/\d/g, "")}>
                      <Img
                        fixed={
                          card.headerImage[0].wideImage.childImageSharp.fixed
                        }
                      />
                    </div>
                  ) : card.headerImage.length &&
                    card.headerImage[0] &&
                    card.headerImage[0].wideImage ? (
                    <img
                      src={card.headerImage[0].wideImage.publicURL}
                      id={card.slug.replace(/\d/g, "")}
                    />
                  ) : (
                    <div
                      className="placeholder"
                      id={card.slug.replace(/\d/g, "")}
                    />
                  )}
                  <H5Medium>{card.title}</H5Medium>
                </a>
              </CommunityCard>
            );
          })}
        </Masonry>
      ) : (
        <p>No community members shown! Try changing your filters?</p>
      )}

      {/*overlayOn ? (
        <Scrim
          onClick={(e) => {
            setOverlayOn(false);
          }}
        />
				) : null */}
      {overlayOn ? (
        <Overlay
          communityMember={currentlySelected}
          photoCoordinates={holderRef.current
            .querySelector(`#${currentlySelected.slug.replace(/\d/g, "")}`)
            .getBoundingClientRect()}
          holderCoordinates={holderRef.current.getBoundingClientRect()}
          closeThis={() => {
            setOverlayOn(false);
          }}
        />
      ) : null}
    </CommunityCardHolderDiv>
  );
};

export default CommunityCardHolder;

CommunityCardHolder.propTypes = {
  cards: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      communityMemberSlug: PropTypes.string,
      title: PropTypes.string,
      slug: PropTypes.string,
    })
  ),
};
