import styled from "styled-components";

export const CommunityMapToggleElement = styled.ul`
  list-style-type: none;
  margin-left: auto;
  margin-right: auto;
  text-align: center;
  margin: 0 0 50px 0;
  padding: 0;
  & li {
    display: inline-flex;
    width: 69px;
    height: 25px;
    background-color: var(--light);
    font-size: 12px;
    letter-spacing: 0.24px;
    & a {
      color: var(--black);
      display: inline-flex;
      justify-content: center;
      align-items: center;
      width: 100%;
      height: 100%;
    }
    &.selected {
      background-color: var(--red);
      & a {
        color: var(--white);
      }
    }
    & + li {
      margin-left: 3px;
    }
  }
`;
