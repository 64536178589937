import React from "react";
import PropTypes from "prop-types";
import { H3Strong, H5SmallMedium } from "./../Typography";
import { CommunityHeroDiv } from "./elements";

const CommunityHero = ({ topLine, text, color }) => (
  <CommunityHeroDiv color={color}>
    <H5SmallMedium>{topLine}</H5SmallMedium>
    <H3Strong>{text}</H3Strong>
  </CommunityHeroDiv>
);

export default CommunityHero;

CommunityHero.propTypes = {
  topLine: PropTypes.string,
  text: PropTypes.string,
};

CommunityHero.defaultProps = {
  topLine: "Discover the Avenue",
};
