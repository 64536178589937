import React from "react";
import PropTypes from "prop-types";
import CoreFilter from "./../CoreFilter";

const communityTypes = [
  { id: "culinary", name: "Culinary", color: "var(--green)" } /* (same) */,
  { id: "art", name: "Art", color: "var(--red)" } /* —> Art*/,
  {
    id: "lifestyleWellness",
    name: "Health & Wellness",
    color: "var(--yellow)",
  },
  {
    id: "cultureEducation",
    name: "Retail & Lifestyle",
    color: "#B5A8F6",
  },
  {
    id: "productionConsultancy",
    name: "Creative Consultancy, Makers & Education",
    color: "var(--pink)",
  }, 
  {
    id: "entertainmentPerformingArts",
    name: "Entertainment & Performing Arts",
    color: "var(--turquoise)",
  }, 
  {
    id: "visitingConcepts",
    name: "Visiting Concepts",
    color: "var(--blue)",
  } 
];

export const getCommunityTypeFromID = (id) => {
  const list = communityTypes.filter((x) => x.id === id);
  if (list.length) {
    return list[0].name;
  } else {
    console.error("Problem: ", id);
  }
};

const CommunityFilter = ({ selected }) => {
  return (
    <CoreFilter
      selected={selected}
      filterList={communityTypes}
      useColor
      modal
    />
  );
};

export default CommunityFilter;

CommunityFilter.propTypes = {
  selected: PropTypes.func,
};
