import styled from "styled-components";

export const CommunityHeroDiv = styled.div`
  --color: ${(props) => props.color || "var(--red)"};
  width: 100%;
  padding: 35px var(--outerMargin);
  & h5 {
    margin-bottom: 25px;
    text-align: center;
  }
  & h3 {
    color: var(--color);
    text-align: center;
    max-width: calc(
      calc(var(--width) - var(--outerMargin)) - var(--outerMargin)
    );
    margin-left: auto;
    margin-right: auto;
  }
  @media (max-width: 767px) {
    padding: 25px 20px;
    & h5 {
      text-align: left;
    }
    & h3 {
      text-align: left;
      font-size: 24px;
      letter-spacing: 0.24px;
    }
  }
  @media (max-width: 767px) {
    --outerMargin: 25px;
  }
`;
